<template>
  <div class="backgroundDiv">
    <div class="nameBlock inputBlock glass">
      <span>密码</span>
      <input type="password" v-model="password" />
    </div>
    <div class="button glass" @click="openGateClicked(password)">确认</div>
  </div>
</template>

<script>
export default {
  name: "CheckAccessibility",
  data() {
    return {
      name: undefined,
    };
  },
  methods: {
    openGateClicked(password) {
      const PASSWORD = 'thisisthepassword'
      if (password === PASSWORD) {
        this.$emit("allowAccess");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$unified_width: 280px;
$outer_border_radius: 30px;
.glass {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  box-shadow: 4px 4px 30px 0 rgba(27, 0, 37, 0.4);
}
div.backgroundDiv {
  width: 100%;
  height: 100%;
  color: white;
  background: linear-gradient(
    135deg,
    hsl(170deg, 80%, 70%),
    hsl(190deg, 80%, 70%),
    hsl(250deg, 80%, 70%),
    hsl(320deg, 80%, 70%)
  );
  background-size: 200% 200%;
  animation: gradient-move 10s ease alternate infinite;
  @keyframes gradient-move {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 100%;
    }
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div.inputBlock {
    width: $unified_width;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 3px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    span {
      width: 4em;
      text-align: center;
      margin-right: 10px;
      border-radius: 15px;
      font-weight: bold;
      letter-spacing: 1px;
      padding: 7px;
      background-color: rgba(0, 0, 0, 0.2);
    }
    input {
      width: 100%;
      letter-spacing: 1px;
      outline: none;
      border: 0;
      color: white;
      background-color: rgba(0, 0, 0, 0);
    }
  }
  div.button {
    width: $unified_width;
    margin-top: 20px;
    padding: 20px 0;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 5px;
    border-radius: $outer_border_radius;
    background-color: rgba(0, 0, 0, 0.4);
    transition: background-color 0.5s ease-out;
  }
  div.button:active {
    background-color: rgba(0, 0, 0, 0.7);
  }
}
</style>
