<template>
  <check-accessibility
    v-if="!isAccessible"
    @allow-access="isAccessible = true"
  />
  <div style="height: 100%" v-if="isAccessible">
    <personal-info v-if="!showFakePass && isAccessible" @my-click="onClick" />
    <fake-pass
      v-if="showFakePass && isAccessible"
      @click-to-return="back"
      :personalInfo="personalInfo"
    />
  </div>
</template>

<script>
import FakePass from "@/components/FakePass";
import PersonalInfo from "@/components/PersonalInfo";
import CheckAccessibility from "@/components/CheckAccessibility";
export default {
  components: { PersonalInfo, FakePass, CheckAccessibility },
  data() {
    return {
      isAccessible: false,
      showFakePass: false,
      personalInfo: {
        name: "易玛㷩",
        stdId: 2020202020,
        dep: "计算机学院（国家示范性软件学院）",
        inOut: "允许出校",
        img: require("@/img/default.jpg"),
      },
    };
  },
  mounted() {
    document.body.addEventListener("touchstart", () => { });
  },
  methods: {
    onClick(pi) {
      this.personalInfo = pi;
      this.showFakePass = true;
    },
    back() {
      this.showFakePass = false;
    },
  },
};
</script>

<style lang="scss">
:root,
body,
#app {
  height: 100%;
}
</style>
